import React from 'react'
import { FlexGrid, FlexGridItem } from 'baseui/flex-grid'
import { Block } from 'baseui/block'
import { useStyletron } from 'baseui'
import { H4 } from 'baseui/typography'

import {
  FacebookIcon,
  TwitterIcon,
  PinterestIcon,
  RedditIcon,
  PocketIcon,
  InstapaperIcon,
  LinkedinIcon,
  EmailIcon,
  FacebookShareButton,
  TwitterShareButton,
  PinterestShareButton,
  RedditShareButton,
  PocketShareButton,
  InstapaperShareButton,
  LinkedinShareButton,
  EmailShareButton
} from 'react-share'

import Heading from './Heading'
import LinkItem from './LinkItem'
import Justify from './Justify'

const Footer = () => {
  const [css, theme] = useStyletron()
  return (
    <Block
      className={css({
        borderTop: `1px solid ${theme.colors.backgroundTertiary}`,
        backgroundColor: '#191919',
        textRendering: 'optimizeLegibility',
        marginTop: theme.sizing.scale2400,
        paddingTop: theme.sizing.scale1400,
        paddingBottom: theme.sizing.scale1400
      })}
    >
      <FlexGrid flexGridColumnCount={[3, 1, 3]} flexGridColumnGap="scale200" flexGridRowGap="scale200">
        <FlexGridItem>
          <Heading title="Us.">
            <LinkItem url="/news" title="News" />
            <LinkItem url="/about" title="About" />
            <LinkItem url="/policies/terms" title="Site Terms" />
            <LinkItem url="/policies/privacy" title="Privacy Policy" />
            <LinkItem url="/contact" title="Contact" />
          </Heading>
        </FlexGridItem>
        <FlexGridItem>
          <Heading title="Explore.">
            <LinkItem url="/explore/breeds" title="Breeds" />
            <LinkItem url="/explore/disciplines" title="Disciplines" />
            <LinkItem url="/explore/level" title="Rider Level" />
            <LinkItem url="/explore/sex" title="Sex" />
            <LinkItem url="/explore/height" title="Height" />
          </Heading>
        </FlexGridItem>
        <FlexGridItem>
          <Heading title="Share.">
            <Block
              className={css({
                display: 'grid',
                gridGap: theme.sizing.scale100,
                gridTemplateColumns: '1fr 1fr'
              })}
            >
              <Justify direction="end">
                <FacebookShareButton url="http://sellmyhorse.com.au/">
                  <FacebookIcon size={32} borderRadius={4} />
                </FacebookShareButton>
              </Justify>
              <Justify>
                <PinterestShareButton media="http://sellmyhorse.com.au/" url="http://sellmyhorse.com.au/">
                  <PinterestIcon size={32} borderRadius={4} />
                </PinterestShareButton>
              </Justify>
              <Justify direction="end">
                <LinkedinShareButton url="http://sellmyhorse.com.au/">
                  <LinkedinIcon size={32} borderRadius={4} />
                </LinkedinShareButton>
              </Justify>
              <Justify>
                <InstapaperShareButton url="http://sellmyhorse.com.au/">
                  <InstapaperIcon size={32} borderRadius={4} />
                </InstapaperShareButton>
              </Justify>
              <Justify direction="end">
                <TwitterShareButton url="http://sellmyhorse.com.au/">
                  <TwitterIcon size={32} borderRadius={4} />
                </TwitterShareButton>
              </Justify>
              <Justify>
                <RedditShareButton url="http://sellmyhorse.com.au/">
                  <RedditIcon size={32} borderRadius={4} />
                </RedditShareButton>
              </Justify>
              <Justify direction="end">
                <PocketShareButton url="http://sellmyhorse.com.au/">
                  <PocketIcon size={32} borderRadius={4} />
                </PocketShareButton>
              </Justify>
              <Justify>
                <EmailShareButton url="http://sellmyhorse.com.au/">
                  <EmailIcon size={32} borderRadius={4} />
                </EmailShareButton>
              </Justify>
            </Block>
          </Heading>
        </FlexGridItem>
      </FlexGrid>

      <Block
        className={css({
          borderTop: '1px solid rgba(255, 255, 255, 0.98)',
          marginLeft: '50px',
          marginRight: '50px',
          marginTop: '10px',
          height: '75px',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center'
        })}
      >
        <H4
          className={css({
            marginTop: 0,
            marginBottom: 0
          })}
          color={'rgba(255, 255, 255, 0.98)'}
        >
          sell
          <span
            className={css({
              color: '#FF391F'
            })}
          >
            my
          </span>
          horse
        </H4>
      </Block>
    </Block>
  )
}

export default Footer
