import React from 'react'
import PropTypes from 'prop-types'
import { useStyletron } from 'baseui'
import { Block } from 'baseui/block'

import Logo from '@global/Logo'
import Navigation from './Navigation'

const Navbar = () => {
  const [css, theme] = useStyletron()

  return <Navigation />
}

export default Navbar
