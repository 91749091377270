import * as React from 'react'
import { useStyletron } from 'baseui'
import { Block } from 'baseui/block'
import { Grid, Cell } from 'baseui/layout-grid'
import { H4 } from 'baseui/typography'

// import Dropdown from './Dropdown'
import NavItem from '@components/Layout/NavItem'
import Logo from '@global/Logo'

const Navigation = () => {
  const [css, theme] = useStyletron()

  return (
    <div
      className={css({
        backgroundColor: '#292929',
        textRendering: 'optimizeLegibility',
        minHeight: '75px'
      })}
    >
      <div
        className={css({
          [`@media screen and (min-width: ${theme.breakpoints.large}px)`]: {
            maxWidth: `${theme.breakpoints.large}px`,
            marginLeft: 'auto',
            marginRight: 'auto'
          }
        })}
      >
        <Grid gridMargins={0} gridGutters={0}>
          <Cell span={[4, 3, 3]}>
            <Block
              display="flex"
              alignItems="center"
              justifyContent={['flex-start', 'center', 'flex-start', 'flex-start']}
              paddingTop={['0', '20px', '0', '0']}
              paddingLeft={['0', '0', '20px', '20px']}
              height="100%"
            >
              <H4
                className={css({
                  marginTop: 0,
                  marginBottom: 0
                })}
                color={'rgba(255, 255, 255, 0.98)'}
              >
                sell
                <span
                  className={css({
                    color: '#FF391F'
                  })}
                >
                  my
                </span>
                horse
              </H4>
              <Block width="75px">
                <Logo />
              </Block>
            </Block>
          </Cell>
          <Cell span={[4, 5, 9]}>
            <Block
              display="flex"
              justifyContent={['flex-end', 'center', 'flex-end', 'flex-end']}
              padding={'10px 0 10px 0'}
            >
              <NavItem label="Home" url="/" />
              <NavItem label="Sell" url="/list" />
              <NavItem label="Explore" url="/search" />
              <NavItem label="Account" url="/account" />
            </Block>
          </Cell>
        </Grid>
      </div>
    </div>
  )
}

export default Navigation
