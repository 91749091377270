import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import Router from 'next/router'
import NProgress from 'nprogress'
import { useStyletron } from 'baseui'

import Container from '@global/Container'
import Meta from './Meta'
import Navbar from './Layout/Navbar'
import Footer from './Layout/Footer'

let timeout

const Layout = ({ children }) => {
  const [css, theme] = useStyletron()

  const start = () => {
    timeout = setTimeout(NProgress.start, 500)
  }

  const done = () => {
    clearTimeout(timeout)
    NProgress.done()
  }

  useEffect(() => {
    Router.events.on('routeChangeStart', start)
    Router.events.on('routeChangeComplete', done)
    Router.events.on('routeChangeError', done)

    return () => {
      Router.events.off('routeChangeStart', start)
      Router.events.off('routeChangeComplete', done)
      Router.events.off('routeChangeError', done)
    }
  }, [])

  return (
    <div>
      <Meta />
      <Navbar />
      <div
        className={css({
          width: '100%',
          display: 'block',
          boxSizing: 'border-box',
          marginLeft: 'auto',
          marginRight: 'auto',
          minHeight: '30vh',
          [theme.mediaQuery.medium]: {
            maxWidth: '1280px'
          },
          [theme.mediaQuery.medium]: {
            paddingLeft: '12px',
            paddingRight: '12px'
          },
          [theme.mediaQuery.small]: {
            paddingLeft: '6px',
            paddingRight: '6px'
          }
        })}
      >
        <div
          className={css({
            marginTop: '15px'
          })}
        >
          <Container>{children}</Container>
        </div>
      </div>
      <Footer />
    </div>
  )
}

Layout.propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node, PropTypes.string])
}

export default Layout
