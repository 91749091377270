import React, { useState } from 'react'
import PropTypes from 'prop-types'
import Link from 'next/link'
import { Button } from 'baseui/button'
import { useStyletron } from 'baseui'
import { useRouter } from 'next/router'

const NavItem = ({ label, url }) => {
  const [css, theme] = useStyletron()
  const router = useRouter()

  return (
    <div>
      <Link href={url} passHref>
        <Button
          $as="a"
          overrides={{
            BaseButton: {
              style: ({ $theme }) => ({
                marginLeft: $theme.sizing.scale400,
                marginRight: $theme.sizing.scale400,
                backgroundColor: '#292929',
                color: $theme.colors.primaryB,
                fontFamily: 'Poppins',
                ':hover': {
                  backgroundColor: 'transparent'
                },
                ':visited': {
                  color: $theme.colors.primaryB
                },
                [`@media screen and (max-width: ${theme.breakpoints.medium}px)`]: {
                  marginLeft: '0',
                  marginRight: '0'
                }
              })
            }
          }}
        >
          <span
            className={css({
              textDecoration: 'none',
              borderBottom: router.pathname === url ? `2px solid ${theme.colors.accent200}` : '2px solid transparent',
              ':hover': {
                borderBottom: `2px solid ${theme.colors.accent300}`
              }
            })}
          >
            {label}
          </span>
        </Button>
      </Link>
    </div>
  )
}

NavItem.propTypes = {
  label: PropTypes.string.isRequired,
  url: PropTypes.string.isRequired
}

export default NavItem
