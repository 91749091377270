import React from 'react'
import PropTypes from 'prop-types'
import { Block } from 'baseui/block'
import { useStyletron } from 'baseui'

const Justify = ({ direction, children }) => {
  const [css] = useStyletron()
  return (
    <Block
      className={css({
        justifySelf: direction
      })}
    >
      {children}
    </Block>
  )
}

Justify.propTypes = {
  direction: PropTypes.string,
  children: PropTypes.element.isRequired
}

Justify.defaultProps = {
  direction: 'start'
}

export default Justify
