import React from 'react'
import PropTypes from 'prop-types'
import { Block } from 'baseui/block'
import { useStyletron } from 'baseui'
import { H4 } from 'baseui/typography'

const Heading = ({ title, children }) => {
  const [css, theme] = useStyletron()
  return (
    <Block
      className={css({
        textAlign: 'center'
      })}
    >
      <H4
        className={css({
          marginTop: theme.sizing.scale400,
          marginBottom: theme.sizing.scale400
        })}
        color={'rgba(255, 255, 255, 0.98)'}
      >
        {title}
      </H4>
      <Block>{children}</Block>
    </Block>
  )
}

Heading.propTypes = {
  title: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired
}

export default Heading
