import React from 'react'
import PropTypes from 'prop-types'
import { useStyletron } from 'baseui'
import { Paragraph4 } from 'baseui/typography'
import Link from 'next/link'

const LinkItem = ({ url, title }) => {
  const [css, theme] = useStyletron()

  return (
    <Link href={url}>
      <a>
        <Paragraph4
          className={css({
            color: 'rgba(255, 255, 255, 0.7)',
            marginTop: theme.sizing.scale100,
            marginBottom: theme.sizing.scale100,
            textDecoration: 'underline',
            ':visited': {
              color: 'rgba(255, 255, 255, 0.7)'
            },
            ':hover': {
              color: 'rgba(255, 255, 255, 1)'
            }
          })}
        >
          {title}
        </Paragraph4>
      </a>
    </Link>
  )
}

LinkItem.propTypes = {
  url: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired
}

export default LinkItem
