import React from 'react'
import PropTypes from 'prop-types'
import { useStyletron } from 'baseui'

const Container = ({ children }) => {
  const [css, theme] = useStyletron()
  return (
    <div
      className={css({
        width: '100%',
        marginLeft: 'auto',
        boxSizing: 'border-box',
        marginRight: 'auto',
        paddingLeft: theme.sizing.scale500,
        paddingRight: theme.sizing.scale500,
        display: 'block', // Fix IE 11 layout when used with main.
        [theme.mediaQuery.large]: {
          maxWidth: `${theme.breakpoints.large}px`,
        },
      })}
    >
      {children}
    </div>
  )
}

Container.propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
}

export default Container
